header {
    height: 100vh;
    padding: 0 1rem;
    padding-top: 2rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

.header__container h1,
.header__container h5 {
    font-weight: 500;
}

/*=================================================================*/
/*                      CTA                          
/*=================================================================*/

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/*=================================================================*/
/*                      Header Socials                          
/*=================================================================*/

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    position: absolute;
    left: 0rem;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 3rem;
    background-color: var(--color-primary);
}

/*=================================================================*/
/*                      ME                          
/*=================================================================*/

.me {
    width: 22rem;
    height: 150vh;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
}

.me img {
    border-radius: 12rem 12rem 0rem 0rem;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    object-position: 50% 65%;
}

/*=================================================================*/
/*                      Scroll Down                          
/*=================================================================*/

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: .9rem;
}

.scroll__down:hover {
    transform: rotate(0deg);
}

/*=================================================================*/
/*                      Media Queries (Small Devices)                         
/*=================================================================*/

@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials,
    .scroll__down {
        display: none;
    }
}