.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    margin: 0 auto;
}

.portfolio__item {
    position: relative;
    background-color: var(--color-bg-variant);
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color-bg-variant);
    background-color: transparent;
}

.portfolio__item-image {
    border-radius: 1rem;
    overflow: hidden;
}

.portfolio__item-image img {
    object-fit: cover;
    height: 150px;
}

.portfolio__item h3 {
    margin-top: 1rem;
}

.portfolio__item_badges {
    display: flex;
    flex-wrap: wrap;
    gap: .3rem;
    margin-top: .5rem;
}

.portfolio__item_badges span {
    font-size: .8rem;
    background-color: var(--color-primary);
    padding: .1rem .5rem;
    border-radius: 1rem;
    margin-right: .1rem;
}

.portfolio__item p {
    margin-top: 1rem;
    margin-bottom: 4rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    position: absolute;
    bottom: 0;
}

.portfolio__button {
    display: flex;
    align-items: center;
    gap: .4rem;
    margin: 2rem auto;
    background-color: var(--color-white);
    padding: 1rem 3rem;
}

.portfolio__button:hover {
    color: var(--color-white);
    background-color: var(--color-bg-variant);
}

/*=================================================================*/
/*                      Media Queries (Medium Devices)                         
/*=================================================================*/

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}


/*=================================================================*/
/*                      Media Queries (Small Devices)                         
/*=================================================================*/

@media screen and (max-width: 600px) {
    .portfolio__container {
        width: var(--container-width-ms);
    }
}